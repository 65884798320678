import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

// 底部链接
export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: '—',
      },
      {
        label: t('Audit documentation'),
        href: '//xxxx.com',
        isHighlighted: true,
      },
      // {
      //   label: t('Brand'),
      //   href: 'https://docs.pancakeswap.finance/brand',
      // },
      // {
      //   label: t('Blog'),
      //   href: 'https://medium.com/pancakeswap',
      // },
      // {
      //   label: t('Community'),
      //   href: 'https://docs.pancakeswap.finance/contact-us/telegram',
      // },
      // {
      //   label: t('Litepaper'),
      //   href: 'https://v2litepaper.pancakeswap.finance/',
      // },
      // {
      //   label: '—',
      // },
      // {
      //   label: t('Online Store'),
      //   href: 'https://pancakeswap.creator-spring.com/',
      // },
    ],
  },
  // {
  //   label: t('Help'),
  //   items: [
  //     {
  //       label: t('Customer Support'),
  //       href: 'https://docs.pancakeswap.finance/contact-us/customer-support',
  //     },
  //     {
  //       label: t('Troubleshooting'),
  //       href: 'https://docs.pancakeswap.finance/help/troubleshooting',
  //     },
  //     {
  //       label: t('Guides'),
  //       href: 'https://docs.pancakeswap.finance/get-started',
  //     },
  //   ],
  // },
  // {
  //   label: t('Developers'),
  //   items: [
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/pancakeswap',
  //     },
  //     {
  //       label: t('Documentation'),
  //       href: 'https://docs.pancakeswap.finance',
  //     },
  //     {
  //       label: t('Bug Bounty'),
  //       href: 'https://docs.pancakeswap.finance/code/bug-bounty',
  //     },
  //     {
  //       label: t('Audits'),
  //       href: 'https://docs.pancakeswap.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited',
  //     },
  //     {
  //       label: t('Careers'),
  //       href: 'https://docs.pancakeswap.finance/hiring/become-a-chef',
  //     },
  //   ],
  // },
]

// 审计文档链接
export const auditDocLink =
  'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-SatoshiIncognito-v1.0.pdf'
