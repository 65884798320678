import { Language } from '@pancakeswap/uikit'

export const AR: Language = { locale: 'ar-SA', language: 'العربية', code: 'ar' } // 阿拉伯语(沙特阿拉伯)
export const BN: Language = { locale: 'bn-BD', language: 'বাংলা', code: 'bn' } // 孟加拉语(孟加拉国)
export const EN: Language = { locale: 'en-US', language: 'English', code: 'en' } // 英语(美国)
export const DE: Language = { locale: 'de-DE', language: 'Deutsch', code: 'de' } // 德语(德国)
export const EL: Language = { locale: 'el-GR', language: 'Ελληνικά', code: 'el' } // 希腊语(希腊)
export const ESES: Language = { locale: 'es-ES', language: 'Español', code: 'es-ES' } // 西班牙语(西班牙)
export const FI: Language = { locale: 'fi-FI', language: 'Suomalainen', code: 'fi' } // 芬兰语(芬兰)
export const FIL: Language = { locale: 'fil-PH', language: 'Filipino', code: 'fil' } // 菲律宾语(菲律宾)
export const FR: Language = { locale: 'fr-FR', language: 'Français', code: 'fr' } // 法语(法国)
export const HI: Language = { locale: 'hi-IN', language: 'हिंदी', code: 'hi' } // 印地语(印度)
export const HU: Language = { locale: 'hu-HU', language: 'Magyar', code: 'hu' } // 匈牙利语(匈牙利)
export const ID: Language = { locale: 'id-ID', language: 'Bahasa Indonesia', code: 'id' } // 印度尼西亚语(印度尼西亚)
export const IT: Language = { locale: 'it-IT', language: 'Italiano', code: 'it' } // 意大利语(意大利)
export const JA: Language = { locale: 'ja-JP', language: '日本語', code: 'ja' } // 日语(日本)
export const KO: Language = { locale: 'ko-KR', language: '한국어', code: 'ko' } //  朝鲜语(韩国)
export const NL: Language = { locale: 'nl-NL', language: 'Nederlands', code: 'nl' } // 荷兰语(荷兰)
export const PL: Language = { locale: 'pl-PL', language: 'Polski', code: 'pl' } // 波兰语(波兰)
export const PTBR: Language = { locale: 'pt-BR', language: 'Português (Brazil)', code: 'pt-br' } // 葡萄牙语(巴西)
export const PTPT: Language = { locale: 'pt-PT', language: 'Português', code: 'pt-pt' } // 葡萄牙语(葡萄牙)
export const RO: Language = { locale: 'ro-RO', language: 'Română', code: 'ro' } // 罗马尼亚语(罗马尼亚)
export const RU: Language = { locale: 'ru-RU', language: 'Русский', code: 'ru' } // 国语(俄罗斯)
export const SVSE: Language = { locale: 'sv-SE', language: 'Svenska', code: 'sv' } // 瑞典语(瑞典)
export const TA: Language = { locale: 'ta-IN', language: 'தமிழ்', code: 'ta' } // 套马语(印度)
export const TR: Language = { locale: 'tr-TR', language: 'Türkçe', code: 'tr' } // 土耳其语(土耳其)
export const UK: Language = { locale: 'uk-UA', language: 'Українська', code: 'uk' } // 乌克兰语(乌克兰)
export const VI: Language = { locale: 'vi-VN', language: 'Tiếng Việt', code: 'vi' } // 越南语(越南)
export const ZHCN: Language = { locale: 'zh-CN', language: '简体中文', code: 'zh-cn' } // 中文(中国)
export const ZHTW: Language = { locale: 'zh-TW', language: '繁體中文', code: 'zh-tw' } // 中文(台湾)

//新增
// 印度语，俄语，德语，法语，葡萄牙语，西班牙语，阿拉伯语

// 泰语，印度语，俄语，德语，法语，葡萄牙语，西班牙语，阿拉伯语，然后中文和繁体中文排到最下面

//多语言配置相关
export const languages = {
  'en-US': EN, // 英语
  'ja-JP': JA, // 日语
  'ko-KR': KO, // 韩语
  'hi-IN': HI, // 印地语
  'ru-RU': RU, // 俄语
  'de-DE': DE, // 德语
  'fr-FR': FR, // 法语
  'pt-PT': PTPT, // 葡萄牙语
  'es-ES': ESES, // 西班牙语
  'ar-SA': AR, // 阿拉伯语
  'zh-CN': ZHCN, // 中文
  'zh-TW': ZHTW, // 中文跟繁体
}

export const languageList = Object.values(languages)
