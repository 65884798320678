/** 2022-07-20 */
// * BSC测试网
//     * WBNB：`0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd`
//     * MockERC20 DAO代币：`0x75F5360506eF6Fe2B76C609ed4c11521141A8422`
//     * SwapToEarn代理合约：`0x298810E38B4d894D44C33a1340e4255d0C3Cc110`
//         * SwapToEarn逻辑合约：`0x6c4c4bF009652E2658Bf9b40f12bE49bf7c10A13`
//     * DAOFactory合约：`0x65190a10FD67F12B4CEd9CAc754C088345db3927`
//     * DAORouter合约：`0xF39eC624e60148f250a629e0cb5a12aab73D3628`

/** 2022-07-26 */

// * INFO: BSC测试网
//     * WBNB：`0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd`
// WARNING: 变更了代币合约地址
//     * MockERC20 DAO代币：`0x458bD95DF7F3Fd682fe2450c4f99eFeb5DF7c0Ba`
// WARNING: 变更了代币合约地址
//     * SwapToEarn代理合约：`0xB0cC5E7E78f0979E93B0b2a419BA59F72beE7888`
// WARNING: 变更了代币合约地址
//         * SwapToEarn逻辑合约：`0x4aE8A1786271afC7C1a37CeD71B7A9941E930787`
//     * DAOFactory合约：`0x65190a10FD67F12B4CEd9CAc754C088345db3927`
//     * DAORouter合约：`0xF39eC624e60148f250a629e0cb5a12aab73D3628`
// WARNING: 新增
//     * BNB-DAO pair合约：`0xE4b6E3223Ca2e4E071B5e627FF0516b9e07c17d9`

/** 2022-07-27 */

// * INFO: BSC测试网
//     * WBNB：`0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd`
//     * MockERC20 DAO代币：`0x458bD95DF7F3Fd682fe2450c4f99eFeb5DF7c0Ba`
//     * SwapToEarn代理合约：`0xB0cC5E7E78f0979E93B0b2a419BA59F72beE7888`
// WARNING: 变更了合约地址
//         * SwapToEarn逻辑合约：`0x4aE8A1786271afC7C1a37CeD71B7A9941E930787`
//     * DAOFactory合约：`0x35211b88e4fdFcc8A695F2A1939eDC70784B8451`
//     * DAORouter合约：`0x8B3c8aD8Cd02AB789dF0Bc0C3413E26DD7831818`
// WARNING: 新增
// * MockERC20 USDT:0x37aD58B5F4EBAF4526B6f0e422deA8b68d6F26F1
// * BNB-DAO pair合约：`0x256D9814bAe34D65DA818Ec8A625Fc9AAB753866`
// * USDT-DAO pair合约：`0xdDC7eDcfC9d246D70F0F178E01A03FD67197bF2C`

/** 2022-08-18 */

// swap2earn proxy=> 0x9d7Cf730D35849053DE2031919Cf87c72aC5EBa1
// swap2earn logic=> 0x448B3aAE2F8a3d5d5F7dc025330E9aF6dCE37dDE
// DAO=> 0x458bD95DF7F3Fd682fe2450c4f99eFeb5DF7c0Ba
// DAOFactory=> 0x2C2620F23Ae0300A9CfE03EC6dF7EbE13aaf878D
// DAORouter=> 0x3b9F7C136407Bf77591ADf09233C8a88668bBF2E
// DAO_BNB_PAIR=> 0xe32B75d841D312E67408bDA5BBeD955b0F2aBC09
// DAO_USDT_PAIR=> 0xA1a16c4133b420af76ecAaFfd04298c4Ac757848

/** 2022-08-19 */
// swap2earn proxy=> 0x7335B0945cb4782c425899A49A295E1e75588D01
// swap2earn logic=> 0xEb358919EB7a552190b621d9E4b03dc9be7BcdEB
// DAO=> 0x458bD95DF7F3Fd682fe2450c4f99eFeb5DF7c0Ba
// DAOFactory=> 0x017c51DA2Ac166c85Fbdc8f4C2B0567D46a7ED9A
// DAORouter=> 0x916e34773f9718d308beEC4882407816D273E010
// DAO_BNB_PAIR=> 0x973961cC12700A1c1B6329ddde7e91765Fc5D949
// DAO_USDT_PAIR=> 0x3A6cf622c5336B1C82F05237F5891Cf80c8c962D

/** 2022-08-21  MAINNET */

// 【挖矿和邀请奖励的合约地址】
// swap2earn proxy=> 0xea41BBD80Ac69807289d0C4F6582AB73E96834D0

// 【DAO的代币合约地址】
// DAO=> 0x037b202Ca88D2028d82936d5615eE5088cb9fD78

// 【daoswap factory地址】
// DAOFactory=> 0x3265CdE5843e02E35c45C4b9352d3C1c4a542Ff4

// 【daoswap router v2 地址】
// DAORouter=> 0xf625DF2BE53Cac287fFbaaCbE3A2E5B9d0994885

// 抽奖 20203/6/10

//  BSC测试网信息

// 部署合约的账号：0x96FE9d7b21Da7ab1F4A49e7948bC0cD020CbC446
// 部署合约的账号私钥：0x4f0b85ec8f5a287775185893a2ac589adc1347b4ff71b5900ca183b0a72d93bf
// 第一个用户的账号：0x45fD463372DaC705e39B3B0f158f0D1281b13129
// 第一个用户的账号私钥：0x440d95fad5c1890d0d63be446e1f994cee3bb382c3f12d239eddfaf784f6d703
// 我项目方基金会的账号：0xfb2740c819855a282F23942F807Fd5c8BF41eAd1
// 我项目方基金会的账号私钥：0x461e2728b4080f82b0ab20e9f41d8228b7326ea9ca2274cc13cd2b6e223b1f97

// 项目方抵押挖矿奖励的mock合约地址：0xbc5aEc75b5E07Bb7FaaF74217992b61BCe9bF899
// 项目方抵押挖矿奖励的mock合约地址私钥：0x9abfaad8227e29cc9de4d4a6bb64f31a58fb791da050c1f0f35afbb7b5502c82

// 合约地址：
// tokenDAOAddress=> 0xf013c79bBE01A4a18b42c90AB59Fe753f107A620
// LotteryTicketUpgradeableProxy address=> 0x7219EDD8852Ae2aaea094d1b6FD567aa5C58Ab9e

//0909
// 部署合约的账号：0x96FE9d7b21Da7ab1F4A49e7948bC0cD020CbC446
// 部署合约的账号私钥：0x4f0b85ec8f5a287775185893a2ac589adc1347b4ff71b5900ca183b0a72d93bf
// 第一个用户的账号：0x45fD463372DaC705e39B3B0f158f0D1281b13129
// 第一个用户的账号私钥：0x440d95fad5c1890d0d63be446e1f994cee3bb382c3f12d239eddfaf784f6d703
// 项目方基金会的账号：0xfb2740c819855a282F23942F807Fd5c8BF41eAd1
// 项目方基金会的账号私钥：0x461e2728b4080f82b0ab20e9f41d8228b7326ea9ca2274cc13cd2b6e223b1f97

// 项目方抵押挖矿奖励的mock合约地址：0xbc5aEc75b5E07Bb7FaaF74217992b61BCe9bF899
// 项目方抵押挖矿奖励的mock合约地址私钥：0x9abfaad8227e29cc9de4d4a6bb64f31a58fb791da050c1f0f35afbb7b5502c82

// 合约地址：
// tokenDAOAddress=> 0xf013c79bBE01A4a18b42c90AB59Fe753f107A620
// LotteryTicketUpgradeableProxy address=> 0x58c8bD18DCE3E03fD6129845bB494DFf7D689930
// rewardsTokenAddress=> 0x05984A84Ea56A06e750217934F008cFcCBAC2abf
// patch 20230630 address => 0xe71249D77970A81C307032312b833ab1E4b4bD47

import { Token, ChainId } from '@pancakeswap/sdk'

//DAO Config 项目开发地址和合约 配置

export default {
  DAOSwapToEarn: {
    [ChainId.MAINNET]: '0xea41BBD80Ac69807289d0C4F6582AB73E96834D0',
    [ChainId.TESTNET]: '0x7335B0945cb4782c425899A49A295E1e75588D01',
  },

  DAOFactory: {
    [ChainId.MAINNET]: '0x3265CdE5843e02E35c45C4b9352d3C1c4a542Ff4',
    [ChainId.TESTNET]: '0x017c51DA2Ac166c85Fbdc8f4C2B0567D46a7ED9A',
  },

  DAORouter: {
    [ChainId.MAINNET]: '0xf625DF2BE53Cac287fFbaaCbE3A2E5B9d0994885',
    [ChainId.TESTNET]: '0x916e34773f9718d308beEC4882407816D273E010',
  },

  DAOToken: {
    [ChainId.MAINNET]: '0x037b202Ca88D2028d82936d5615eE5088cb9fD78',
    [ChainId.TESTNET]: '0xf013c79bBE01A4a18b42c90AB59Fe753f107A620',
  },

  USDT: {
    [ChainId.MAINNET]: '0x55d398326f99059fF775485246999027B3197955',
    [ChainId.TESTNET]: '0x37aD58B5F4EBAF4526B6f0e422deA8b68d6F26F1',
  },

  WBNB: {
    [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'DA0', 'DA0 Token'),
    [ChainId.TESTNET]: new Token(ChainId.TESTNET, '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd', 18, 'DAO', 'DAO Token'),
  },

  //DAO 代币
  DAO: {
    [ChainId.MAINNET]: new Token(
      ChainId.MAINNET,
      '0x037b202Ca88D2028d82936d5615eE5088cb9fD78',
      18,
      'DA0',
      'DA0 Token',
      '',
    ),
    [ChainId.TESTNET]: new Token(
      ChainId.TESTNET,
      '0xf013c79bBE01A4a18b42c90AB59Fe753f107A620',
      18,
      'DAO',
      'DAO Token',
      '',
    ),
  },
  //彩票地址
  DAOLotteryContract: {
    [ChainId.MAINNET]: '0xE71A487706A065aE0947576F8E591732360d39fb',
    [ChainId.TESTNET]: '0x47fB2B67b3fE91f2B9858d6Cd43B061c14249430',
  },

  //代币 1 dao
  DAOLotteryPatchContract: {
    [ChainId.MAINNET]: '0xDaBce784D6556C794A58484A9cBB25D783878d31',
    [ChainId.TESTNET]: '0x7f3b6007522Feba066545D54B9e4f939910eF5F6',
  },

  //代币 2
  DAOLotteryPatch2Contract: {
    [ChainId.MAINNET]: '0x09d4Fa0b40D38a4ce28f81A8b726fcFfcB57fF8B',
    [ChainId.TESTNET]: '0x7f3b6007522Feba066545D54B9e4f939910eF5F6',
  },

  //代币 3 奖励合约
  DAOLotteryPatch3Contract: {
    [ChainId.MAINNET]: '0x09d4Fa0b40D38a4ce28f81A8b726fcFfcB57fF8B',
    [ChainId.TESTNET]: '0x7f3b6007522Feba066545D54B9e4f939910eF5F6',
  },

  //代币 ETHD 代币地址
  DAOLotteryRewardsETHDContract: {
    [ChainId.MAINNET]: '0xedadfc60402ec6466f317a6fbde66cc0c6eaec20',
    [ChainId.TESTNET]: '0x05984A84Ea56A06e750217934F008cFcCBAC2abf',
  },

  //质押合约地址
  DAOStakeContract: {
    [ChainId.MAINNET]: '0x53572cE7CD8DCa5882B56CF767b64AdB0b60A072',
    [ChainId.TESTNET]: '0x76957EE92Dfe536E1Ca70B8DBd6B5A7f4Ef6DbaF',
  },

  //多签地址
  DAOMultisignContract: {
    [ChainId.MAINNET]: '0xf45CFE0BD90B9fAB4aDCE8E6f7f6fA5b2B8Ddd2b',
    [ChainId.TESTNET]: '0xcf55aDf8feeA664609D12298E7053BD77Ca84934',
  },
  // daotoken
  DAOStakeToken: {
    [ChainId.MAINNET]: '0x037b202ca88d2028d82936d5615ee5088cb9fd78',
    [ChainId.TESTNET]: '0xf013c79bBE01A4a18b42c90AB59Fe753f107A620',
  },
  // daotoken
  DAOTokenNew: {
    [ChainId.MAINNET]: '0x037b202ca88d2028d82936d5615ee5088cb9fd78',
    [ChainId.TESTNET]: '0xf013c79bBE01A4a18b42c90AB59Fe753f107A620',
  },

  DAOSwapToEarnNew: {
    [ChainId.MAINNET]: '0x53572cE7CD8DCa5882B56CF767b64AdB0b60A072',
    [ChainId.TESTNET]: '0x76957EE92Dfe536E1Ca70B8DBd6B5A7f4Ef6DbaF',
  },
}
