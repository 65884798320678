import React, { useState } from 'react'
import { HelpIcon } from '@pancakeswap/uikit'

export const BAD_SRCS: { [imageSrc: string]: true } = {}

export interface LogoProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  srcs?: string[]
  size?: number | string
}

/**
 * Renders an image by sequentially trying a list of URIs, and then eventually a fallback triangle alert
 */
const Logo: React.FC<LogoProps> = ({ srcs, alt, ...rest }) => {
  const [, refresh] = useState<number>(0)

  const src: string | undefined = srcs.find((s) => !BAD_SRCS[s])


  if (src) {
    return (
      <img
        {...rest}
        alt={alt}
        src={src}
        onError={() => {
          if (src) BAD_SRCS[src] = true
          refresh((i) => i + 1)
        }}
      />
    )
  }

  return <HelpIcon {...rest} />
}

export const LogoPure: React.FC<LogoProps> = ({ alt, src, size = 24, ...rest }) => {
  const [, refresh] = useState<number>(0)

  return (
    <img
      {...rest}
      width={size}
      alt={alt}
      src={src}
      onError={() => {
        refresh((i) => i + 1)
      }}
    />
  )
}

export default Logo
